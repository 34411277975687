import { defineStore } from 'pinia';
import { getItem } from '@/assets/js/storage.js';

export const tool = defineStore('tool', {
  
  state: () => {
    return {
      codeValue: 0,
      deviceTemp: {},
      routeList: [],
      sideBar: getItem('sideBar'),
      sidetag: getItem('sidetag'),
      userInfo: getItem('userInfo'),
      sideButton: getItem('sideButton'),
      sideView: getItem('sideView'),
      deviceStatus: '',
    }
  },
  actions: {
    setCodeValue(data) {
      this.codeValue = data;
    },
    setDeviceTemp(data) {
      this.deviceTemp = data;
    },
    setRouteList(data) {
      this.routeList = data;
    },
    setSideBar(data) {
      this.sideBar = data;
    },
    setSidetag(data) {
      this.sidetag = data;
    },
    setUserInfo(data) {
      this.userInfo = data;
    },
    setSideButton(data) {
      this.sideButton = data;
    },
    setSideView(data) {
      this.sideView = data;
    },
    setDeviceStatus(data) {
      this.deviceStatus = data;
    }
  },
  getters:{
    getHeight: (state) => {
      // return state.data.height;
    }
  }
})