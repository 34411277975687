export default[
  {
    path: "/login",
    name: "login",
    meta:{
      title: '登录页'
    },
    component: () => import("../../views/login.vue")
  },
  {
    path: "/screen",
    name: "screen",
    meta:{
      title: '数据大屏',
      permission: ''
    },
    component: () => import("../../views/screen.vue")
  }
]
