export default[
  {
    path: "/",
    name: "page",
    meta:{
      title: '首页',
      scroll: true
    },
    component: () => import("@/views/layout.vue"),
    redirect: '/waste',
    children: [
      {
        path: "/waste",
        name: "waste",
        meta:{
          title: '固废管理',
          scroll: true,
          permission: "waste"
        },
        component: () => import("@/views/waste/index.vue"),
        redirect: '/waste/feeding',
        children: [
          {
            path: '/waste/feeding',
            name: 'waste/feeding',
            meta:{
              title: '投料记录',
              scroll: true,
              permission: "waste:deliveryDetail",
              keepAlive: false
            },
            component: () => import("@/views/waste/feeding.vue"),
          },
          {
            path: '/waste/feedingDetail',
            name: 'waste/feedingDetail',
            meta:{
              title: '投料详情',
              scroll: true,
              permission: ''
            },
            component: () => import("@/views/waste/feedingDetail.vue"),
          },
          {
            path: '/waste/storage',
            name: 'waste/storage',
            meta:{
              title: '暂存单',
              scroll: true,
              permission: "waste:storageDetail"
            },
            component: () => import("@/views/waste/storage.vue"),
          },
          {
            path: '/waste/storageDetail',
            name: 'waste/storageDetail',
            meta:{
              title: '暂存单详情',
              scroll: true,
              permission: ''
            },
            component: () => import("@/views/waste/storageDetail.vue"),
          },
          {
            path: '/waste/orderIn',
            name: 'waste/orderIn',
            meta:{
              title: '入库单',
              scroll: true,
              permission: "waste:orderIn"
            },
            component: () => import("@/views/waste/orderIn.vue"),
          },
          {
            path: '/waste/orderInDetail',
            name: 'waste/orderInDetail',
            meta:{
              title: '入库单详情',
              scroll: true,
              permission: ''
            },
            component: () => import("@/views/waste/orderInDetail.vue"),
          },
          {
            path: '/waste/orderOut',
            name: 'waste/orderOut',
            meta:{
              title: '出库单',
              scroll: true,
              permission: "waste:orderOut"
            },
            component: () => import("@/views/waste/orderOut.vue"),
          },
          {
            path: '/waste/orderOutDetail',
            name: 'waste/orderOutDetail',
            meta:{
              title: '出库单详情',
              scroll: true,
              permission: ''
            },
            component: () => import("@/views/waste/orderOutDetail.vue"),
          },
          {
            path: '/waste/good',
            name: 'waste/good',
            meta:{
              title: '拉货单',
              scroll: true,
              permission: "waste:orderCargo"
            },
            component: () => import("@/views/waste/good.vue"),
          },
          {
            path: '/waste/good-recl',
            name: 'waste/good-recl',
            meta:{
              title: '拉货单详情',
              scroll: true,
              permission: ''
            },
            component: () => import("@/views/waste/good-recl.vue"),
          },
          {
            path: '/waste/settlement',
            name: 'waste/settlement',
            meta:{
              title: '结账单',
              scroll: true,
              permission: "waste:orderSettle"
            },
            component: () => import("@/views/waste/settlement.vue"),
          },
          {
            path: '/waste/destructionCert',
            name: 'waste/destructionCert',
            meta:{
              title: '销毁证明',
              scroll: true,
              permission: "waste:destroyProve"
            },
            component: () => import("@/views/waste/destructionCert.vue"),
          },
          {
            path: '/waste/destructionCertDetail',
            name: 'waste/destructionCertDetail',
            meta:{
              title: '销毁证明详情',
              scroll: true,
              permission: ''
            },
            component: () => import("@/views/waste/destructionCertDetail.vue"),
          },
          {
            path: '/waste/settledetail',
            name: 'waste/settledetail',
            meta:{
              title: '结账单详情',
              scroll: true,
              permission: ''
            },
            component: () => import("@/views/waste/settledetail.vue"),
          },
          {
            path: '/waste/collectDetail',
            name: 'waste/collectDetail',
            meta:{
              title: '修正详情',
              scroll: true,
              permission: ''
            },
            component: () => import("@/views/waste/collectDetail.vue"),
          },
          {
            path: '/waste/success',
            name: 'waste/success',
            meta:{
              title: '工业固废智能管家',
              scroll: true,
              permission: ''
            },
            component: () => import("@/views/waste/success.vue"),
          }
        ]
      },
      {
        path: "/danger",
        name: "danger",
        meta:{
          title: '危废管理',
          scroll: true,
          permission: "hw"
        },
        component: () => import("@/views/danger/index.vue"),
        redirect: '/danger/delivery',
        children: [
          {
            path: '/danger/delivery',
            name: 'danger/delivery',
            meta:{
              title: '危废产生记录',
              scroll: true,
              permission: "hw:delivery"
            },
            component: () => import("@/views/danger/delivery.vue"),
          },
          {
            path: '/danger/deliveryDetail',
            name: 'danger/deliveryDetail',
            meta:{
              title: '危废产生记录详情',
              scroll: true,
              permission: ''
            },
            component: () => import("@/views/danger/deliveryDetail.vue"),
          },
          {
            path: '/danger/storageIn',
            name: 'danger/storageIn',
            meta:{
              title: '危废入库记录',
              scroll: true,
              permission: "hw:storageIn"
            },
            component: () => import("@/views/danger/storageIn.vue"),
          },
          {
            path: '/danger/storageInDetail',
            name: 'danger/storageInDetail',
            meta:{
              title: '危废入库记录详情',
              scroll: true,
              permission: ''
            },
            component: () => import("@/views/danger/storageInDetail.vue"),
          },
          {
            path: '/danger/storageOut',
            name: 'danger/storageOut',
            meta:{
              title: '危废出库记录',
              scroll: true,
              permission: "hw:storageOut"
            },
            component: () => import("@/views/danger/storageOut.vue"),
          },
          {
            path: '/danger/storageOutDetail',
            name: 'danger/storageOutDetail',
            meta:{
              title: '危废出库记录详情',
              scroll: true,
              permission: ''
            },
            component: () => import("@/views/danger/storageOutDetail.vue"),
          }
        ]
      },
      {
        path: "/material",
        name: "material",
        meta:{
          title: '原料管理',
          scroll: true,
          permission: "materiel"
        },
        component: () => import("@/views/material/index.vue"),
        children: [
          {
            path: "/material/today",
            name: "material/today",
            meta:{
              title: '今日行情',
              scroll: true,
              permission: "materiel:price"
            },
            component: () => import("@/views/material/today.vue")
          },
          {
            path: "/material/quotation",
            name: "material/quotation",
            meta:{
              title: '行情导入',
              scroll: true,
              permission: "materiel:market"
            },
            component: () => import("@/views/material/quotation.vue")
          }
        ]
      },
      {
        path: "/customer",
        name: "customer",
        meta:{
          title: '客户管理',
          scroll: true,
          permission: "company:list"
        },
        component: () => import("@/views/customer/index.vue"),
        children: [
          {
            path: '/customer/list',
            name: 'customer/list',
            meta:{
              title: '客户管理',
              scroll: true
            },
            component: () => import("@/views/customer/list.vue"),
          },
          {
            path: '/customer/monitor',
            name: 'customer/monitor',
            meta:{
              title: '设备监控',
              scroll: true
            },
            component: () => import("@/views/customer/monitor.vue"),
          },
          {
            path: '/customer/device',
            name: 'customer/device',
            meta:{
              title: '设备列表',
              scroll: true
            },
            component: () => import("@/views/customer/device.vue"),
          },
          {
            path: '/customer/deviceDetail',
            name: 'customer/deviceDetail',
            meta:{
              title: '设备属性',
              scroll: true
            },
            component: () => import("@/views/customer/deviceDetail.vue"),
          },
          {
            path: '/customer/stock',
            name: 'customer/stock',
            meta:{
              title: '设备库存',
              scroll: true
            },
            component: () => import("@/views/customer/stock.vue"),
          },
          {
            path: '/customer/user',
            name: 'customer/user',
            meta:{
              title: '查看用户',
              scroll: true
            },
            component: () => import("@/views/customer/user.vue"),
          },
        ]
      },
      {
        path: '/device',
        name: 'device',
        meta:{
          title: '设备管理',
          scroll: true,
          permission: "device"
        },
        component: () => import("@/views/device/index.vue"),
        children: [
          {
            path: '/device/list',
            name: 'device/list',
            meta:{
              title: '设备列表',
              scroll: true,
              permission: "device:list"
            },
            component: () => import("@/views/device/list.vue"),
          },
          {
            path: '/device/func',
            name: 'device/func',
            meta:{
              title: '运行日志',
              scroll: true,
              permission: "device:log"
            },
            component: () => import("@/views/device/func.vue"),
          },
          {
            path: '/device/warn',
            name: 'device/warn',
            meta:{
              title: '异常警告',
              scroll: true,
              permission: "device:warning"
            },
            component: () => import("@/views/device/warn.vue"),
          }
        ]
      },
      {
        path: "/operates",
        name: "operates",
        meta:{
          title: '运维单位',
          scroll: true,
          permission: "operator:list"
        },
        component: () => import("@/views/operate/index.vue"),
        redirect: '/operates/list',
        children: [
          {
            path: "/operates/list",
            name: "operates/list",
            meta:{
              title: '运维单位',
              scroll: true
            },
            component: () => import("@/views/operate/list.vue")
          },
          {
            path: "/operates/customer",
            name: "operates/customer",
            meta:{
              title: '客户管理',
              scroll: true
            },
            component: () => import("@/views/operate/customer.vue")
          },
        ]
      },
      {
        path: "/analysis",
        name: "analysis",
        meta:{
          title: '数据分析',
          scroll: true,
          permission: "ds"
        },
        component: () => import("@/views/analysis/index.vue"),
        redirect: '/analysis/data',
        children: [
          {
            path: "/analysis/data",
            name: "analysis/data",
            meta:{
              title: '大屏配置',
              scroll: true,
              permission: "ds:ls"
            },
            component: () => import("@/views/analysis/data.vue")
          }
        ]
      },
      {
        path: "/mobile",
        name: "mobile",
        meta:{
          title: '移动设置',
          scroll: true,
          permission: "ma"
        },
        component: () => import("@/views/mobile/index.vue"),
        redirect: '/mobile/news',
        children: [
          {
            path: "/mobile/news",
            name: "mobile/news",
            meta:{
              title: '新闻资讯',
              scroll: true,
              permission: "ma:news"
            },
            component: () => import("@/views/mobile/news.vue")
          },
          {
            path: "/mobile/waste",
            name: "mobile/waste",
            meta:{
              title: '原料行情',
              scroll: true,
              permission: "ma:materielMarket"
            },
            component: () => import("@/views/mobile/waste.vue")
          },
          {
            path: "/mobile/appoint",
            name: "mobile/appoint",
            meta:{
              title: '预约上门',
              scroll: true,
              permission: "ma:reserve"
            },
            component: () => import("@/views/mobile/appoint.vue")
          },
          {
            path: "/mobile/scrap",
            name: "mobile/scrap",
            meta:{
              title: '废品设置',
              scroll: true
            },
            component: () => import("@/views/mobile/scrap.vue")
          },
          {
            path: "/mobile/opinion",
            name: "mobile/opinion",
            meta:{
              title: '意见反馈',
              scroll: true,
              permission: "ma:feedback"
            },
            component: () => import("@/views/mobile/opinion.vue")
          }
        ]
      },
      {
        path: "/setting",
        name: "setting",
        meta:{
          title: '系统配置',
          scroll: true,
          permission: "setting"
        },
        component: () => import("@/views/setting/index.vue"),
        redirect: '/setting/device',
        children: [
          {
            path: "/setting/operate",
            name: "setting/operate",
            meta:{
              title: '运营配置',
              scroll: true,
              permission: "setting:operator"
            },
            component: () => import("@/views/setting/operate/index.vue")
          },
          {
            path: "/setting/operateCard",
            name: "setting/operateCard",
            meta:{
              title: '收料卡管理',
              scroll: true
            },
            component: () => import("@/views/setting/operate/operateCard.vue")
          },
          {
            path: "/setting/operateCustomer",
            name: "setting/operateCustomer",
            meta:{
              title: '客户配置',
              scroll: true
            },
            component: () => import("@/views/setting/operate/operateCustomer.vue")
          },
          {
            path: "/setting/operateUser",
            name: "setting/operateUser",
            meta:{
              title: '客户管理',
              scroll: true
            },
            component: () => import("@/views/setting/operate/operateUser.vue")
          },
          {
            path: "/setting/company",
            name: "setting/company",
            meta:{
              title: '客户配置',
              scroll: true,
              permission: "setting:company"
            },
            component: () => import("@/views/setting/company/index.vue")
          },
          {
            path: "/setting/storehouse",
            name: "setting/storehouse",
            meta:{
              title: '仓库配置',
              scroll: true
            },
            component: () => import("@/views/setting/company/storehouse.vue")
          },
          {
            path: "/setting/companyUser",
            name: "setting/companyUser",
            meta:{
              title: '用户管理',
              scroll: true
            },
            component: () => import("@/views/setting/company/companyUser.vue")
          },
          {
            path: "/setting/deviceCard",
            name: "setting/deviceCard",
            meta:{
              title: '卡配置',
              scroll: true
            },
            component: () => import("@/views/setting/company/deviceCard.vue")
          },
          {
            path: "/setting/deviceDeploy",
            name: "setting/deviceDeploy",
            meta:{
              title: '设备配置',
              scroll: true
            },
            component: () => import("@/views/setting/company/deviceDeploy.vue")
          },
          {
            path: "/setting/deviceDetail",
            name: "setting/deviceDetail",
            meta:{
              title: '设备详情',
              scroll: true
            },
            component: () => import("@/views/setting/company/deviceDetail.vue")
          },
          {
            path: "/setting/waste",
            name: "setting/waste",
            meta:{
              title: '废料配置',
              scroll: true
            },
            component: () => import("@/views/setting/company/waste.vue")
          },
          {
            path: "/setting/dispose",
            name: "setting/dispose",
            meta:{
              title: '设施配置',
              scroll: true,
              permission: "setting:company:disposeFacility"
            },
            component: () => import("@/views/setting/company/dispose.vue")
          },
          {
            path: "/setting/device",
            name: "setting/device",
            meta:{
              title: '设备类型',
              scroll: true,
              permission: "setting:deviceType"
            },
            component: () => import("@/views/setting/device.vue")
          },
          {
            path: "/setting/user",
            name: "setting/user",
            meta:{
              title: '后台用户',
              scroll: true,
              permission: "setting:sysUser"
            },
            component: () => import("@/views/setting/user.vue")
          },
          {
            path: "/setting/role",
            name: "setting/role",
            meta:{
              title: '角色管理',
              scroll: true,
              permission: "setting:secRole"
            },
            component: () => import("@/views/setting/role.vue")
          },
          {
            path: "/setting/dictionary",
            name: "setting/dictionary",
            meta:{
              title: '数据字典',
              scroll: true,
              permission: "setting:sysDict"
            },
            component: () => import("@/views/setting/dictionary.vue")
          },
          {
            path: "/setting/running",
            name: "setting/running",
            meta:{
              title: '操作日志',
              scroll: true,
              permission: "setting:sysOperateLog"
            },
            component: () => import("@/views/setting/running.vue")
          },
          {
            path: "/setting/material",
            name: "setting/material",
            meta:{
              title: '原料配置',
              scroll: true,
              permission: "setting:materiel"
            },
            component: () => import("@/views/setting/material.vue")
          },
          {
            path: "/setting/warning",
            name: "setting/warning",
            meta:{
              title: '预警配置',
              scroll: true,
              permission: "setting:alertConfig"
            },
            component: () => import("@/views/setting/warning.vue")
          },
          {
            path: "/setting/process",
            name: "setting/process",
            meta:{
              title: '流程配置',
              scroll: true
            },
            component: () => import("@/views/setting/company/process.vue")
          },
          {
            path: "/setting/product",
            name: "setting/product",
            meta:{
              title: '产品配置',
              scroll: true
            },
            component: () => import("@/views/setting/product.vue")
          },
          {
            path: "/setting/maintain",
            name: "setting/maintain",
            meta:{
              title: '运维配置',
              scroll: true
            },
            component: () => import("@/views/setting/maintain/index.vue")
          },
          {
            path: "/setting/maintainUser",
            name: "setting/maintainUser",
            meta:{
              title: '用户管理',
              scroll: true
            },
            component: () => import("@/views/setting/maintain/maintainUser.vue")
          },
          {
            path: "/setting/maintainCustomer",
            name: "setting/maintainCustomer",
            meta:{
              title: '用户管理',
              scroll: true
            },
            component: () => import("@/views/setting/maintain/maintainCustomer.vue")
          },
          {
            path: "/setting/appSecret",
            name: "setting/appSecret",
            meta:{
              title: 'AppSecret管理',
              scroll: true
            },
            component: () => import("@/views/setting/appSecret.vue")
          },
          {
            path: "/setting/bluePrinter",
            name: "setting/bluePrinter",
            meta:{
              title: '便携式打印机',
              scroll: true
            },
            component: () => import("@/views/setting/bluePrinter.vue")
          },
          {
            path: "/setting/carList",
            name: "setting/carList",
            meta:{
              title: '车辆配置',
              scroll: true
            },
            component: () => import("@/views/setting/carList.vue")
          },
          {
            path: "/setting/fieldlList",
            name: "setting/fieldlList",
            meta:{
              title: '场地配置',
              scroll: true
            },
            component: () => import("@/views/setting/fieldlList.vue")
          },
          {
            path: "/setting/monitorList",
            name: "setting/monitorList",
            meta:{
              title: '监控配置',
              scroll: true
            },
            component: () => import("@/views/setting/monitorList.vue")
          }
        ]
      },
    ]
  }
]
