import home from './home.js';
import order from './order.js';
const notFound = [{
    path: "/404",
    name: '404',
    component: () => import("../../views/404.vue")
  },
  {
    path: '/:pathMatch(.*)*', // vue2可以使用 * ，vue3使用/:pathMatch(.*)*或/:pathMatch(.*)或/:catchAll(.*)
    redirect: {
      name: "404"
    }
  }
]
export default [...order, ...home, ...notFound]
