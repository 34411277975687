<template>
  <el-config-provider :locale="locale">
    <RouterView />
  </el-config-provider>
</template>

<script setup>
import '@/assets/font/iconfont.css';
import { ref } from 'vue';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import { post } from '@/api';
import { getItem, setItem } from './assets/js/storage';
import { useRouter } from 'vue-router';

const $router = useRouter();

const storage = ['sidetag', 'userInfo', 'sideButton', 'sideView', 'sideBar', 'route', 'token', 'socket']; // 本地所有缓存
storage.some(e => {
  if(!getItem(e)){
    localStorage.clear();
    $router.push('/login');
    return true;
  }
})

// 强制重新登录，只需要修改ver的版本号就可以了
const getUser = () => {
  post('/portal/userInfo');
}
if(getItem('token')){
  getUser();
}


const locale = ref(zhCn);
</script>

<style lang="scss">
@import url("./assets/css/reset.css");
@import url("./assets/css/common.css");
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  position: relative;
  font-weight: normal;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html,
body {
  overflow: hidden;
  background-color: #F5FAF5;
}

img {
  width: 100%;
}
.td .el-form-item{
  margin-bottom: 8px;
}
.box{
  padding: 14px;
  background-color: #fff;
  height: calc(100% - 42px);
}
.page{
  padding-top: 14px;
}
.td .element-icons{
  font-size: 14px !important;
  margin-right: 6px;
}
.tableBtn.el-popover .el-select{
  .el-input{
    .el-input__wrapper{
      padding-right: 7px;
    }
    
  }
}
.tableBtn.el-popover .el-input{
  .el-input__wrapper{
    padding: 0;
    .el-input__inner{
      padding: 0 10px;
    }
  }
}
.el-dialog__header{
  padding-bottom: 20px !important;
}
.form-button .el-form-item__content {
	justify-content: center;
	margin-top: 50px;
}
</style>
