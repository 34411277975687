import { defineStore } from 'pinia'

export const formData = defineStore('formData', {
  state: () => {
    return{
      content: {},
      // 大屏数据
      screen: '',
      monthWaste: '',
      quotas: '',
      wasteStat: '',
      // 大屏数据 end
    }
  },
  actions: {
    setData(data) {
      this.content = data;
    },
    setScreen(data){
      this.screen = data;
    },
    setMonthWaste(data){
      this.monthWaste = data;
    },
    setQuotas(data){
      this.quotas = data;
    },
    setWasteStat(data){
      this.wasteStat = data;
    }
  },
  getters:{
    getData: (state) => {
      return state.content;
    }
  },
})
