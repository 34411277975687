import timesFormats from "./timeFormat.js";
// import md5Libs from "./md5.js";
import settings from "./setting.js";
import tests from "./test.js";
import deepClones from "./deepClone.js";
// 计算精度
import { Decimal } from 'decimal.js'

export const test = tests;
export const setting = settings;
export const timesFormat = timesFormats;
export const deepClone = deepClones;
// 设置数字保留后两位
export const setNumber = (num, leng = 2) => {	
	num = num.toString().split(".");
	if (num.length == 2 && num[1].length > leng) {
		num[1] = num[1].substring(0, leng);
	}
	num = num.join(".");
	return Number(num);
}
/**
 * 深度复制数组
 *
 * @param arr 需要复制的数组
 * @returns 复制后的数组
 */
export const deepArr =(arr) => {
	var list = [];
	arr.forEach(item => {
		list.push(item);
	});
	return list;
}
//除法
export const numberDiv = (num1, num2, fls = true) =>{
	var n1 = Number(num1);
	var n2 = Number(num2);
	var number = 0;
	// 是否所有数据都进行计算，默认为是
	if(fls){
		number = new Decimal(n1).div(new Decimal(n2)).toNumber();
	}else{
		number = n1 < n2 ? n1 : new Decimal(n1).div(new Decimal(n2)).toNumber();
	}
	return Number(number)
}
// 判断对象是否为空
export const checkObj = (obj) => {
	for (var key in obj) {
		return false;
	}
	return true;
}
/**
 * 设置值
 *
 * @param row 行对象
 * @param arr 字段数组
 * @returns 返回第一个匹配到的字段值，如果未找到则返回空字符串
 */
export const setValue = (row, arr) => {
	var str = '';
	arr.some(e => {
		if (row[e]) {
			str = row[e];
			return true;
		}
	});
	return str;
}
// 处理数据字典的数据
/**
 * 将对象数组转换为以code为键，name为值的对象
 *
 * @param data 对象数组，每个对象包含code和name属性
 * @returns 转换后的对象，以code为键，name为值
 */
export const setForDic = (data) => {
  let obj = {};
  for (const key in data) {
    obj[data[key].code] = data[key].name;
  }
  return obj;
}
/**
 * @description 数字格式化
 * @param {number|string} number 要格式化的数字
 * @param {number} decimals 保留几位小数
 * @param {string} decimalPoint 小数点符号
 * @param {string} thousandsSeparator 千分位符号
 * @returns {string} 格式化后的数字
 */
export const priceFormat = (number, decimals, decimalPoint = '.', thousandsSeparator = ',') => {
	number = (`${number}`).replace(/[^0-9+-Ee.]/g, '')
	const n = !isFinite(+number) ? 0 : +number
	const prec = !isFinite(+decimals) ? '' : Math.abs(decimals)
	const sep = (typeof thousandsSeparator === 'undefined') ? ',' : thousandsSeparator
	const dec = (typeof decimalPoint === 'undefined') ? '.' : decimalPoint
	let s = ''
  
  const last = number.split('.')[1];
  const num = (prec ? setNumber(n, prec) + '' : last ? setNumber(n, last.length) : `${Math.round(n)}`).toString();
	s = num.split('.')
  
	const re = /(-?\d+)(\d{3})/
	while (re.test(s[0])) {
		s[0] = s[0].replace(re, `$1${sep}$2`)
	}
	if ((s[1] || '').length < prec) {
		s[1] = s[1] || ''
		s[1] += new Array(prec - s[1].length + 1).join('0')
	}
	return s.join(dec)
}